import { DateTime } from "luxon";
import { marked } from "marked"

export class News {
  id: number
  title: string
  body: string
  published_at: string

  get disp_published_at() {
    return DateTime.fromISO(this.published_at).toFormat('yyyy.LL.dd')
  }

  get detail_path() {
    return `/news/${this.id}`
  }

  get parsed_body() {
    return marked(this.body)
  }

  toJSON() {
    return { ...this }
  }
}
